import React from 'react';
import {useParams} from 'react-router-dom';
import {Container, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const DonationSuccess = () => {
    // Extract the donation ID from the URL parameters
    const { productId } = useParams();
    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                p: 4,
                backgroundColor: '#f9f9f9',
                textAlign: 'center',
            }}
        >
            <CheckCircleIcon
                color="success"
                sx={{ fontSize: 100, mb: 2 }}
            />
            <Typography variant="h4" component="h1" gutterBottom>
                Donation Successful
            </Typography>
            <Typography variant="h6" component="p" sx={{ mb: 4 }}>
                Thank you for your donation!
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 4 }}>
                Your donation reference: <strong>{productId}</strong>
            </Typography>
        </Container>
    );
};

export default DonationSuccess;
