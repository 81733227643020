import React from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';

const ImageTextSection = ({ title, imageUrl, children }) => {
    return (
        <Box sx={{ mb: 6 }}>
            <Typography variant="h3" gutterBottom>
                {title}
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={3}>
                    <Box
                        component="img"
                        src={imageUrl}
                        alt={title}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 1,
                            boxShadow: 2,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Divider orientation="vertical" flexItem sx={{ mr: 2, borderColor: 'grey.400' }} />
                        <Box
                            component="div"
                            sx={{ typography: 'body1' }}
                            // Render HTML content
                            dangerouslySetInnerHTML={{ __html: children }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 4 }} />
        </Box>
    );
};

export default ImageTextSection;
