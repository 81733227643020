import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Container, FormControlLabel, Switch, TextField, Typography} from '@mui/material';
import api from "../api/api";

function AddAppeal() {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [active, setActive] = useState(true);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await api.post('/products', {
                name,
                description,
                image,
                active
            });
            navigate('/appeals');  // Redirect to the product list page after adding a product
        } catch (error) {
            console.error('There was an error adding the product!', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    Add New Product
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <TextField
                        label="Product Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                    <TextField
                        label="Image URL"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={image}
                        onChange={(e) => setImage(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                        label="Active"
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Add Product
                    </Button>
                </form>
            </Box>
        </Container>
    );
}

export default AddAppeal;
