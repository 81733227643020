import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Pagination,
    TextField,
    Typography
} from '@mui/material';
import {toast} from "react-toastify";
import api from "../api/api";

const AppealList = () => {
    const [products, setProducts] = useState([]);
    const [customAmount, setCustomAmount] = useState({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        api.get(`/products?page=${page - 1}&size=10`)
            .then(response => {
                setProducts(response.data.content);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching appeals:', error);
                toast.error('Failed to load appeals. Please try again later.');
            });
    }, [page]);

    const handleDonate = (productId, amount) => {
        api.post(`/products/${productId}/donate`, {amount})
            .then(response => window.location.href = response.data.returnUrl)
            .catch(error => {
                console.error('Error donating:', error);
                toast.error('Failed to process the donation. Please try again later.');
            });
    };

    const handleCustomAmountChange = (productId, event) => {
        setCustomAmount({
            ...customAmount,
            [productId]: event.target.value
        });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <Box sx={{p: 2}}>
            <Typography variant="h4" gutterBottom>
                Products
            </Typography>
            <List>
                {products?.map(product => (
                    <ListItem
                        key={product.id}
                        sx={{
                            mb: 3,
                            p: 2,
                            borderRadius: 1,
                            boxShadow: 2,
                            backgroundColor: '#ffffff',
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: {xs: 'column', sm: 'row'},
                            gap: 2
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                variant="square"
                                src={product.image}
                                alt={product.name}
                                sx={{
                                    width: {xs: '100px', sm: '120px'},
                                    height: {xs: '100px', sm: '120px'},
                                    objectFit: 'cover'
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant="h6">{product.name}</Typography>}
                            secondary={<Typography variant="body2"
                                                   color="textSecondary">{product.description}</Typography>}
                            sx={{flexGrow: 1}}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 1,
                                width: '100%',
                                maxWidth: '300px',
                                marginTop: {xs: 2, sm: 0}
                            }}
                        >
                            <TextField
                                label="Custom Amount (€)"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={customAmount[product.id] || ''}
                                onChange={(e) => handleCustomAmountChange(product.id, e)}
                                sx={{mb: 2}}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleDonate(product.id, customAmount[product.id] || 0)}
                            >
                                Donate
                            </Button>
                            <Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
                                {[20, 50, 100, 200].map(amount => (
                                    <Button
                                        key={amount}
                                        variant="outlined"
                                        color="primary"
                                        sx={{mx: 1}}
                                        onClick={() => handleDonate(product.id, amount)}
                                    >
                                        {amount} €
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    </ListItem>
                ))}
            </List>

            {/* Pagination Control */}
            <Box sx={{display: 'flex', justifyContent: 'center', mt: 4}}>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
        </Box>
    );
};

export default AppealList;
