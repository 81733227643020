import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Box, Menu, MenuItem, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const Header = ({ user }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAdminMenu = (event) => {
        setAdminAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAdminAnchorEl(null);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#333', boxShadow: 'none', borderBottom: '1px solid #ddd' }}>
            <Container>
                <Toolbar disableGutters>
                    {/* Logo */}
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" noWrap component={Link} to="/" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                            <img src="https://waqf.lu/wp-content/uploads/2022/04/250-1.png" alt="FONDATION WAQF LËTZEBUERG" style={{ maxHeight: '50px', marginRight: '10px' }} />
                        </Typography>
                    </Box>

                    {/* Main Navigation */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                        <Button component={Link} to="/" sx={{ my: 2, color: 'black', display: 'block' }}>Home</Button>
                        <Button component={Link} to="/waqf" sx={{ my: 2, color: 'black', display: 'block' }}>Waqf Lëtzebuerg</Button>
                        <Button component={Link} to="/zakat" sx={{ my: 2, color: 'black', display: 'block' }}>ZAKAT</Button>
                        <Button component={Link} to="/appeals" sx={{ my: 2, color: 'black', display: 'block' }}>NOS PROJETS</Button>
                        <Button component={Link} to="/donations" sx={{ my: 2, color: 'black', display: 'block' }}>List donation</Button>

                        {/* Admin Dropdown Menu - Visible only to Admin users */}
                        {/*{user && user.role === 'admin' && (*/}
                        {true && (
                            <Box sx={{ ml: 2 }}>
                                <Button
                                    aria-controls="admin-menu"
                                    aria-haspopup="true"
                                    onClick={handleAdminMenu}
                                    sx={{ my: 2, color: 'black', display: 'block' }}
                                >
                                    ADMIN
                                </Button>
                                <Menu
                                    id="admin-menu"
                                    anchorEl={adminAnchorEl}
                                    keepMounted
                                    open={Boolean(adminAnchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose} component={Link} to="/admin/appeals">Appeals</MenuItem>
                                    <MenuItem onClick={handleClose} component={Link} to="/admin/donations">Donations</MenuItem>
                                    <MenuItem onClick={handleClose} component={Link} to="/admin/users">Users</MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Box>

                    {/* Donate Button */}
                    <Button component={Link} to="/appeals" variant="contained" color="primary" sx={{ marginLeft: 2 }}>
                        FAIRE UN DON
                    </Button>

                    {/* Mobile Menu Icon */}
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ ml: 2, display: { md: 'none' } }}
                        onClick={handleMenu}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </Container>

            {/* Mobile Menu */}
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} component={Link} to="/">Home</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/waqf">Waqf Lëtzebuerg</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/zakat">ZAKAT</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/appeals">Appeals</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/donations">NOS PROJETS</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/contact-us">Contact</MenuItem>

                {/* Admin Menu Items for Mobile View */}
                {/*{user && user.role === 'admin' && (*/}
                {true && (
                    <>
                        <MenuItem onClick={handleClose} component={Link} to="/admin/appeals">Admin Appeals</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/admin/donations">Admin Donations</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/admin/users">Admin Users</MenuItem>
                    </>
                )}
            </Menu>
        </AppBar>
    );
};

export default Header;
