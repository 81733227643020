import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import AppealList from './pages/AppealList';
import DonationList from './pages/DonationList';
import DonationSuccess from './pages/DonationSuccess';
import AddAppeal from './pages/AddAppeal';
import Layout from "./components/Layout";
import ZakatCalculator from "./pages/ZakatCalculator";
import Home from "./pages/Home";
import WaqfPage from "./pages/Waqf";

function App() {
    return (
        <Router>
            <Layout>
                {/* Include ToastContainer here */}
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />

                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/appeals" element={<AppealList/>}/>
                    <Route path="/appeals/add" element={<AddAppeal/>}/>
                    <Route path="/donations" element={<DonationList/>}/>
                    <Route path="/donations/:productId/success" element={<DonationSuccess/>}/>
                    <Route path="/zakat" element={<ZakatCalculator/>}/>
                    <Route path="/waqf" element={<WaqfPage/>}/>
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
