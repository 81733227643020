import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
            }}
        >
            <Container maxWidth="md">
                <Typography variant="body1">
                    © {new Date().getFullYear()} DonationApp. All rights reserved.
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    <Link href="#" color="inherit">
                        Privacy Policy
                    </Link>
                    {' | '}
                    <Link href="#" color="inherit">
                        Terms of Service
                    </Link>
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;
