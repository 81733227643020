import axios from 'axios';

// Create an Axios instance with a base URL
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // This reads the API URL from your environment variable
    // You can add other default settings like headers here
    // headers: { 'Authorization': 'Bearer YOUR_TOKEN' }
});

// Optionally, you can add request/response interceptors if needed
/*api.interceptors.request.use(
    (config) => {
        // Modify the request config before sending it, e.g., add tokens, etc.
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        // Any response modifications
        return response;
    },
    (error) => {
        // Handle response errors
        return Promise.reject(error);
    }
);*/

export default api;
