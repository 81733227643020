import React from 'react';
import {Box, Container} from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import Carousel from "./Carousel";

function Layout({ children }) {

    // Array of image URLs for the carousel
    const carouselImages = [
        'https://muslimhands.org.uk/_ui/images/767ba4068a0b.jpg',
        'https://muslimhands.org.uk//_ui/images/196255c0ac00.jpg',
    ];


    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <Header />
                <Box sx={{ mb: 4 }}>
                    <Carousel images={carouselImages} />
                </Box>
                <Container component="main" sx={{ mt: 4, mb: 2, flexGrow: 1 }}>
                    {children}
                </Container>
                <Footer />
            </Box>
        </Box>

    );
}

export default Layout;
