import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const ZakatCalculator = () => {
    const [netIncome, setNetIncome] = useState('');
    const [savings, setSavings] = useState('');
    const [assets, setAssets] = useState('');
    const [debts, setDebts] = useState('');
    const [expenses, setExpenses] = useState('');
    const [zakatAmount, setZakatAmount] = useState(null);

    const handleCalculate = () => {
        const netAssets = parseFloat(savings || '0') + parseFloat(assets || '0') - parseFloat(debts || '0');
        const zakatDue = (netAssets - parseFloat(expenses || '0')) * 0.025; // 2.5% of net assets
        setZakatAmount(zakatDue.toFixed(2));
    };

    return (
        <Box sx={{ p: 3, maxWidth: '600px', mx: 'auto' }}>
            <Typography variant="h4" gutterBottom>
                Zakat Calculator
            </Typography>
            <Box sx={{ mb: 2 }}>
                <Tooltip title="Enter your total net income, i.e., income after taxes and other deductions." arrow>
                    <TextField
                        label="Net Income"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={netIncome}
                        onChange={(e) => setNetIncome(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton edge="end" aria-label="info">
                                    <InfoIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </Tooltip>
            </Box>
            <Box sx={{ mb: 2 }}>
                <Tooltip title="Enter your total savings, which include all liquid assets like cash and bank balances." arrow>
                    <TextField
                        label="Savings"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={savings}
                        onChange={(e) => setSavings(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton edge="end" aria-label="info">
                                    <InfoIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </Tooltip>
            </Box>
            <Box sx={{ mb: 2 }}>
                <Tooltip title="Enter the total value of physical assets like property, vehicles, etc." arrow>
                    <TextField
                        label="Assets"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={assets}
                        onChange={(e) => setAssets(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton edge="end" aria-label="info">
                                    <InfoIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </Tooltip>
            </Box>
            <Box sx={{ mb: 2 }}>
                <Tooltip title="Enter your total amount of debts, including loans and credit card balances." arrow>
                    <TextField
                        label="Debts"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={debts}
                        onChange={(e) => setDebts(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton edge="end" aria-label="info">
                                    <InfoIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </Tooltip>
            </Box>
            <Box sx={{ mb: 2 }}>
                <Tooltip title="Enter your total monthly expenses, such as rent, utilities, and groceries." arrow>
                    <TextField
                        label="Expenses"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={expenses}
                        onChange={(e) => setExpenses(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton edge="end" aria-label="info">
                                    <InfoIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </Tooltip>
            </Box>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleCalculate}
            >
                Calculate Zakat
            </Button>
            {zakatAmount && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6">
                        Total Zakat Due: {zakatAmount} EUR
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ZakatCalculator;
