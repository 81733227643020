import React, {useEffect, useState} from 'react';
import {Avatar, Box, CircularProgress, List, ListItem, ListItemAvatar, Pagination, Typography} from '@mui/material';
import api from "../api/api";

const DonationList = () => {
    const [donations, setDonations] = useState([]);
    const [page, setPage] = useState(1); // Current page
    const [totalPages, setTotalPages] = useState(1); // Total pages
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        setLoading(true);
        api.get(`/donations?page=${page - 1}&size=3`)
            .then(response => {
                setDonations(response.data.content);
                setTotalPages(response.data.totalPages);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching donations:', error);
                setLoading(false);
            });
    }, [page]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    if (loading) {
        return (
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
                Donations
            </Typography>
            <List>
                {donations?.map(donation => (
                    <ListItem
                        key={donation.id}
                        sx={{
                            mb: 2,
                            p: 2,
                            borderRadius: 1,
                            boxShadow: 2,
                            backgroundColor: '#ffffff',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2,
                            width: '100%',
                            overflow: 'hidden'
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                variant="square"
                                src={donation.product.image}
                                alt={donation.product.name}
                                sx={{
                                    width: { xs: '80px', sm: '120px' },
                                    height: { xs: '80px', sm: '120px' },
                                    objectFit: 'cover'
                                }}
                            />
                        </ListItemAvatar>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" noWrap sx={{ mb: 1 }}>
                                {donation.product.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                                Amount: €{donation.amount}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                                Status: {donation.status}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                Email: {donation.email}
                            </Typography>
                        </Box>
                    </ListItem>
                ))}
            </List>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{ mb: 2 }}
                />
            </Box>
        </Box>
    );
};

export default DonationList;
