import React from 'react';
import {Box, Container, Typography} from '@mui/material';


const HomePage = () => {
    return (
        <Box>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Welcome to Our Platform
                </Typography>
                <Typography variant="body1" paragraph>
                    Discover our latest products, events, and initiatives. We are committed to making a positive impact in the community. Explore our offerings and find out how you can contribute.
                </Typography>
            </Container>
        </Box>
    );
};

export default HomePage;
