// src/pages/WaqfPage.js
import React from 'react';
import {Box} from '@mui/material';
import ImageTextSection from "../components/ImageTextSection";

// Sample images for the sections
const imageUrl = 'https://via.placeholder.com/500x300?text=Image';

const WaqfPage = () => {
    return (
        <Box sx={{padding: 2}}>

            <ImageTextSection imageUrl="https://waqf.lu/wp-content/uploads/2020/04/dd-236x300.png"
                              title="La FONDATION WAQF LËTZEBUERG">{`
                <div>
                    
                    <p>
                        a été reconnue d’utilité publique, ce qui signifie que les dons en faveur du WAQF sont fiscalement
                        déductibles en tant que dépenses spéciales dans le chef des donateurs dans les limites des articles 109
                        et 112 L.I.R. Pour être déductibles, les dons doivent être faits directement au WAQF et non pas aux
                        mosquées. La FONDATION WAQF LËTZEBUERG a été fondée par la SHOURA le 13 septembre 2018.
                    </p>
    
                    <p>
                        Sa vocation primaire est d’initier, de soutenir et de contribuer à des projets dont l’intérêt touche
                        directement la communauté musulmane du Grand-Duché, en particulier le WAQF pourra collecter les dons en
                        faveur des mosquées pour financer leur fonctionnement et leurs projets. Il s’agit alors d’une fondation
                        à caractère non lucratif qui œuvre pour le bien commun et la solidarité sociale. Cette fondation récolte
                        des aumônes et des donations qui financent des projets sur le long term.
                    </p>
                    
                    
                </div>
            `}
            </ImageTextSection>

            {/* Specific Section with Detailed Content */}
            <ImageTextSection
                title="Objectifs"
                imageUrl={imageUrl}
            >
                {`
                <div>
                    La Fondation a pour objet d’initier, soutenir et de contribuer:
                    <ul>
                        <li>à la formation et à l’animation d’une recherche en profondeur sur ce qu’est l’Islam, à la
                            religion, civilisation et culture.
                        </li>
                        <li>au soutien des échanges et de la coopération entre les lieux de cultes musulmans du
                            Luxembourg.
                        </li>
                        <li>à la promotion de la réflexion sur le monde actuel et sur son avenir.</li>
                        <li>à l’organisation des actions humanitaires.</li>
                    </ul>

                    La Fondation réalise son objet en poursuivant notamment les activités suivantes:
                    <ul>
                        <li>Organisation d’expositions.</li>
                        <li>Tenue de conférences et de séminaires.</li>
                        <li>Organisation d’évènements cultuels et culturels.</li>
                        <li>Publication d’œuvres de l’esprit.</li>
                        <li>Faciliter et soutenir le fonctionnement des associations islamiques au Grand-duché de
                            Luxembourg.
                        </li>
                        <li>à des projets dont l’intérêt touche directement à la communauté musulmane du Grand-duché.
                        </li>
                    </ul>
                </div>
              `}
            </ImageTextSection>

            <ImageTextSection imageUrl={imageUrl}
                              title="Le Waqf">{`
                <div>
                    
                    <p>
                        Le Waqf dans la tradition musulmane est une donation faite à perpétuité par un particulier à une œuvre d’utilité publique, pieuse ou charitable, ou à un ou plusieurs individus. Le bien donné en usufruit est dès lors placé sous séquestre et devient inaliénable. Selon diverses traditions Mouhamad a encouragé les premiers disciples à effectuer des donations de cette nature.
                    </p>
    
                    <p>
                        En témoigne la réponse faite à l’un de ses compagnons, Omar ibn al-Khattâb, qui l’interrogeait pour savoir comment il devait utiliser une terre qu’il avait acquise, et s’il fallait la donner en aumône : Mouhamad lui conseilla d’immobiliser le fonds et de donner en aumône le produit qu’il en retirerait. La pratique des donations waqf a commencé à se répandre après la mort de Mouhamad et s’est progressivement généralisée. Il pouvait s’agir de dons de terres, voire de grands domaines, de jardins, mais aussi de maisons d’habitation, de boutiques, de fours, de bains, de pressoirs, d’écuries, de caravansérails, d’auberges, etc.
                    </p>
                </div>
            `}
            </ImageTextSection>
        </Box>
    );
};

export default WaqfPage;
